<template>
  <div style="width:50%">
    <el-form label-width="100px">
      <el-row :gutter="10">
        <el-col :span="12">
          <el-form-item label="考试ID：">
            {{testPaperInfo.testPaperId}}
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="考试名称：">
            {{testPaperInfo.testName}}
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="科目：">
            {{testPaperInfo.subjectName}}
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="年级：">
            {{testPaperInfo.gradeName}}
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="参考人数：">
            {{testPaperInfo.number}}
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="考试时间：">
            {{testPaperInfo.number}}
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="结束时间：">
            {{testPaperInfo.number}}
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
export default {
  data () {
    return {

    }
  },
  props: ['testPaperInfo']
}
</script>

<style>
</style>