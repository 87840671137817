<template>
  <div style="width:60%">
    <el-table :data="list">
      <el-table-column label="排名"
                       align="center">
        <template slot-scope="scope">
          {{scope.$index+1}}
        </template>
      </el-table-column>
      <el-table-column label="名称"
                       align="center"
                       prop="truename" />
      <el-table-column label="分数"
                       align="center"
                       prop="userScore" />
    </el-table>
    <pagination ref="pagination"
                :get-data="initData"
                :now-page.sync="page"
                :now-size.sync="size"
                :total="totalElements" />
  </div>
</template>

<script>
import { getSort } from '@/api/analyze.js'

export default {
  data () {
    return {
      page: 1,
      size: 10,
      totalElements: 0,
      list: []
    }
  },
  methods: {
    async initData (page, limit) {
      let params = {
        testPaperId: this.$route.query.test_paper_id,
        page, limit
      }
      const { data } = await getSort(params)
      console.log(data)
      this.totalElements = data.total
      this.list = data.list
    }
  }
}
</script>

<style>
</style>