<template>
  <div style="width:60%;margin-left:20px">
    <el-table :data="list">
      <el-table-column label="知识点id"
                       prop="knowledgeId"
                       align="center" />
      <el-table-column label="知识点名称"
                       align="center"
                       prop="knowledgeDesc" />
      <el-table-column label="得分率"
                       align="center"
                       prop="avgScore">
        <template slot-scope="{row}">
          {{row.avgKnowledge}}%
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { getPaperKnowledgeScores } from '@/api/analyze.js'
export default {
  data () {
    return {
      list: []
    }
  },
  mounted () {
    this.getInfo()
  },
  methods: {
    async getInfo () {
      let params = {
        testPaperId: this.$route.query.test_paper_id
      }
      const { data } = await getPaperKnowledgeScores(params)
      // console.log('data', data)
      this.list = data

    }
  }
}
</script>

<style lang="scss" scoped>
</style>