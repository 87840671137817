<template>
  <div style="width:60%;margin-left:20px">
    <el-tabs v-model="activeName">
      <el-tab-pane v-for="(item,index) in list"
                   :key="index"
                   :label="`题号:${item.serialInPaper}`"
                   :name="item.serialInPaper + ''">
        <el-table :data="item.questionKnowledgeScores">
          <el-table-column label="知识点id"
                           prop="knowledgeId"
                           align="center" />
          <el-table-column label="知识点名称"
                           align="center"
                           prop="knowledgeDesc" />
          <el-table-column label="得分率"
                           align="center"
                           prop="avgScore">
            <template slot-scope="{row}">
              {{row.avgScore}}%
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { getQuestionKnowledgeScores } from '@/api/analyze.js'
export default {
  data () {
    return {
      activeName: '',
      list: []
    }
  },
  mounted () {
    this.getInfo()
  },
  methods: {
    async getInfo () {
      let params = {
        testPaperId: this.$route.query.test_paper_id
      }
      const { data } = await getQuestionKnowledgeScores(params)
      // console.log('data', data)
      this.list = data
      this.activeName = this.list[0].serialInPaper + ''
    }
  }
}
</script>

<style lang="scss" scoped>
</style>